body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.change-endpoint-button {
  align-items: flex-start;
  appearance: button;
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 3px;
  box-sizing: border-box;
  color: #606060;
  cursor: pointer;
  display: block;
  font-size: 13px;
  font-style: normal;
  line-height: 19.5px;
  margin: 0 0 0 0.5rem;
  outline-color: #606060;
  outline-style: none;
  outline-width: 0;
  overflow-x: visible;
  overflow-y: visible;
  padding: 5px 10px;
  text-align: center;
  text-indent: 0;
  text-rendering: auto;
  text-shadow: none;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  white-space: nowrap;
  word-spacing: 0;
  writing-mode: horizontal-tb;
}

.change-endpoint-button:hover {
  background-color: #f2f2f2;
}
